import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import { Helmet } from "react-helmet"


const OneOff = ({ title, summary, preview, slug, url }) => {
  console.log("ONE OFF")
  let pageLink = url ? url : "/" + slug
  return (
    <a className="group" href={pageLink}>
      <div className="flex flex-row flex-wrap md:flex-nowrap w-full md:border-4 md:border-black rounded-xl overflow-hidden m-5 pb-0">
        <div className="flex-shrink-0 w-full md:w-1/2 mr-2 overflow-hidden">
          <img src={preview ? preview.publicURL : ""} />
        </div>
        <div className="flex-col bg-white p-2">
          <h1 className="text-xl font-bold group-hover:underline">{title}</h1>
          <p>{summary}</p>
        </div>
      </div>
    </a>
  );
};

const PostsPage = ({ data }) => {
  return (
    <main className="flex flex-row flex-wrap w-full h-full bg-white text-black justify-center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>dana calacci // art</title>
      </Helmet>
      <Header large={false} />

      {/* <SketchTwo data={data} /> */}
      <div className="flex flex-col w-full p-5 justify-center content-center md:w-2/3">

        <h1 className="text-3xl font-bold self-start mb-5">art & exhibitions</h1>
        {data.allMdx.nodes.map((o) => (
          <OneOff
            title={o.frontmatter.title}
            summary={o.frontmatter.summary}
            preview={o.frontmatter.preview_image}
            slug={o.slug}
            key={o.slug}
            url={o.frontmatter.url}
          />
        ))}
      </div>
    </main>
  );
};

export const query = graphql`
      query PostQuery {
        allMdx(
          filter: {frontmatter: {templateKey: { in: ["oneoffs"] } } }
      sort: {fields: frontmatter___date, order: DESC }
      ) {
        nodes {
        id
        body
      slug
      frontmatter {
        summary
          preview_image {
        publicURL
      }
      title
      url
      date(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
      `;

export default PostsPage;
